import React from "react";
import ReactDOM from "react-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import { Root } from "./Root";

import { initBackend } from "@discoverr/web/backend";

LicenseInfo.setLicenseKey(
  "b7b2442373816fd6971b16729723d096Tz01MTQ3OCxFPTE2OTU4MjI5ODc0MzcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

initBackend({
  apiHostname: process.env["REACT_APP_API"] as string,
});

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
