import React from "react";

import "./translations/i18n";
import { useTranslation } from "react-i18next";

import { Router, Routes, Route, Loadable } from "@discoverr/web/Router";
import { Bootstrap } from "@discoverr/web/Bootstrap";
import { SpaceAuthLoginPage } from "@discoverr/web/SpaceAuth";
import { useNavigate } from "@discoverr/web/Router";
import { getTheme, ThemeType } from "@discoverr/web/themeOld";
import da from "@discoverr/web/languages/da";
import en from "@discoverr/web/languages/en";
import daDate from "date-fns/locale/da";
import { daDK } from "@discoverr/web/languages/date-pickers/daDK";
import { useServiceItemStore } from "stores/serviceItemShoppingCart";

const loginView = require("./assets/login-image.png");

// Constants
const theme = getTheme(
  ThemeType.Light,
  {
    main: "#BF0A30",
    contrastText: "#fff",
  },
  {
    main: "#3C4148",
  },
  undefined,
  undefined,
  [daDK]
);

const languages = {
  da,
  en,
} as const;

// Views
const LoadableApp = Loadable(React.lazy(() => import("./views/App")));

// Components
const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLoginSuccess = () => {
    useServiceItemStore.getState().resetItems();
    navigate("/");
  };

  return (
    <SpaceAuthLoginPage
      title="NCN Group Portal"
      subtitle="Log på den interne platform"
      image={loginView}
      onSuccess={handleLoginSuccess}
      usernameLabel={t("login.initials")}
    />
  );
};

//
export const Root = () => {
  const { i18n } = useTranslation();
  return (
    <Bootstrap
      titleTemplate="N.C. Nielsen - %s"
      language={languages[i18n.language as "da" | "en"]}
      dateFnsLocale={daDate}
      theme={theme}
    >
      <Router>
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/*" element={<LoadableApp />} />
        </Routes>
      </Router>
    </Bootstrap>
  );
};
