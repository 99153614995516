import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import da from "./da.json";
import en from "./en.json";

export const defaultNS = "translation";
export const resources = {
  da,
  en,
} as const;

i18next.use(initReactI18next).init({
  lng: "da",
  ns: [defaultNS],
  defaultNS,
  resources,
  fallbackLng: "da",
});
