import {
  create,
  createStoreSelectors,
  combine,
  subscribeWithSelector,
} from "@discoverr/web/store";
import { useBackendStore } from "@discoverr/web/backend";
import { useSpaceAuthStore } from "@discoverr/web/SpaceAuth";
// Constants
const LOCAL_STORAGE_KEY = "SERVICE_ITEM_SHOPPING_CART_STORAGE";
const setCache = (state: IServiceItemShoppingCartItem[] | null) =>
  setTimeout(() => {
    useSpaceAuthStore.getState().setStorageItem(LOCAL_STORAGE_KEY, state);
  }, 100);
const readCache = () => {
  try {
    const cache =
      useSpaceAuthStore.getState().getStorageItem(LOCAL_STORAGE_KEY) || [];
    return cache;
  } catch (e) {
    return [];
  }
};

// Types
export type IServiceItemShoppingCartItem = {
  "No.": string;
  Description: string;
  quantity: number;
  "Unit Price": number;
};

//

export const useServiceItemStore = create(
  subscribeWithSelector(
    combine(
      { items: readCache() as IServiceItemShoppingCartItem[] },
      (set, get) => ({
        addItem: (item: IServiceItemShoppingCartItem) => {
          set(({ items }) => {
            const index = items.findIndex(
              (cartItem) => cartItem["No."] === item["No."]
            );
            if (index !== -1) {
              return {
                items: [
                  ...items.slice(0, index),
                  {
                    ...items[index],
                    quantity: items[index].quantity + item.quantity,
                  },
                ].concat(items.slice(index + 1)),
              };
            }
            return { items: [...items, item] };
          });
        },
        deleteItem: (item: IServiceItemShoppingCartItem) => {
          set(({ items }) => {
            const index = items.findIndex(
              (cartItem) => cartItem["No."] === item["No."]
            );
            return {
              items: items.slice(0, index).concat(items.slice(index + 1)),
            };
          });
        },
        setItemQuantity: (
          item: IServiceItemShoppingCartItem,
          quantity: number
        ) => {
          set(({ items }) => {
            const index = items.findIndex(
              (foundItem) => foundItem["No."] === item["No."]
            );
            return {
              items: [
                ...items.slice(0, index),
                { ...item, quantity },
                ...items.slice(index + 1),
              ],
            };
          });
        },
        resetItems: () => {
          set({ items: [] });
        },
        order: async ({
          comment,
          priority,
          transferFromCode,
          transferToCode,
          shippingAdvice,
          addressTo,
          nameTo,
          postcodeTo,
          cityTo,
          shippingDate,
        }: {
          comment: string;
          priority: number;
          transferFromCode: string;
          transferToCode: string;
          shippingAdvice: string;
          addressTo: string;
          nameTo: string;
          postcodeTo: string;
          cityTo: string;
          shippingDate: string;
        }) => {
          try {
            const res = await useBackendStore.getState().backend.post(
              "/spaceQuery/dynamics/custom/50005:service_item_function_order",
              {
                priority,
                comment,
                transferFromCode,
                transferToCode,
                lines: get().items,
                shippingAdvice,
                addressTo,
                nameTo,
                postcodeTo,
                cityTo,
                shippingDate,
              },
              {
                params: {
                  tenant: useSpaceAuthStore.getState().tenant,
                },
              }
            );
            set({ items: [] });
            if (res.data[0]) {
              return res.data[0]["Document No."];
            }
            return null;
          } catch (error) {
            throw error;
          }
        },
      })
    )
  )
);

export const serviceItemSelectors = createStoreSelectors(useServiceItemStore, {
  items: (state) => state.items,
});

useServiceItemStore.subscribe((state) => state.items, setCache);
